import React from "react";
import { Button, Input, Select, Typography, message } from "antd";
import api from "../../api";
import useNotification from "../../hooks/useNotification";
import moment from "moment";
import { useState } from "react";
import { translate } from "../../translation/Translator";

const ItemText = ({ changeFunc, label, value }) => {
  return (
    <span
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingTop: 10,
      }}
    >
      <Typography.Text strong style={{ flex: 1, paddingRight: 10 }}>
        {label}{" "}
      </Typography.Text>
      <Typography.Text style={{ flex: 2 }}>{value}</Typography.Text>
    </span>
  );
};
export default function Request({ profile, data, dispatchReload, noteList }) {
  const [ROLE] = React.useState(localStorage.getItem("role"));
  const { pushNotifications } = useNotification();
  const [currentReason, setCurrentReason] = useState(data[data.length - 1]?.error || "");
  const [editReason, setEditReason] = useState(false);

  const request = data ? data[data.length - 1] : null;
  async function sumbitRequest(isAccept, error) {
    if (isAccept) {
      await api.put(`/requests/${request._id}`, {
        status: "onhold",
        amount: request.amount,
      });
      pushNotifications({
        to: request.userId,
        message: translate('NotificationMessage3'),
        description: translate('NotificationMessage4'),
      });
      message.success("Cập nhật thành công");
    } else {
      await api.put(`/requests/${request._id}`, {
        status: "rejected",
        amount: request.amount * -1,
        error: error
      });
      pushNotifications({
        to: request.userId,
        message: currentReason,
        description: translate('NotificationMessage5') + currentReason,
      });
      message.success("Cập nhật thành công");
    }

    dispatchReload();
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        padding: 20,
        borderRadius: 10,
        minWidth: 420,
        margin: "0px 10px",
      }}
    >
      {request ? (
        <>
          <Typography.Title
            level={5}
            style={{ textAlign: "center", marginBottom: "0" }}
          >
            Lệnh rút tiền
          </Typography.Title>
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <ItemText
              label="Ngân hàng"
              value={
                <>
                  <img
                    style={{ marginRight: "20px" }}
                    height={"30px"}
                    src={profile?.kyc?.bank.logo}
                    alt="loading"
                  />
                  {`(${profile?.kyc?.bank.bankName})`}
                </>
              }
            />
            <ItemText label="Số tài khoản" value={profile?.kyc?.bank.number} />
            <ItemText label="Lệnh rút" value="" />
            <div
              style={{
                overflowY: "scroll",
                height: "150px",
                marginTop: "10px",
              }}
            >
              {data &&
                data.map((item) => {
                  return (
                    <div>
                      <span style={{ marginRight: "20px", fontWeight: "500" }}>
                        {moment(item.created_at).format("DD/MM/YYYY HH:mm:ss")}
                      </span>
                      <span
                        style={{}}
                      >{`Lệnh rút ${item.amount.toLocaleString()} vnđ`}</span>
                    </div>
                  );
                })}
            </div>
          </div>

          <div
            className="reason"
            style={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "100%"
            }}>
              {
                editReason ? (
                  <Input
                    value={currentReason}
                    onChange={(e) => setCurrentReason(e.target.value)}
                  />
                ) : (
                  <Select
                    style={{ width: "100%" }}
                    placeholder={currentReason}
                    value={currentReason}
                    onChange={(e) => setCurrentReason(e)}
                  >
                    {noteList.map((item) => (
                      <Select.Option value={`${item.value}`}>
                        {item.value}
                      </Select.Option>
                    ))}
                  </Select>
                )
              }

              <Button
                onClick={() => {
                  setEditReason(!editReason)
                  if (editReason) {
                    setCurrentReason(noteList[0]?.value || "")
                  }
                }}
              >
                {editReason ? "Huỷ" : "Tuỳ chỉnh"}
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: "12px",
              }}
            >
              <p
                style={{
                  margin: "12px 0",
                  padding: "8px",
                  border: "1px solid #000",
                  width: "fit-content",
                }}
                onClick={() => sumbitRequest(false, currentReason)}
              >
                Từ chối
              </p>
              <p
                style={{
                  margin: "12px 0",
                  padding: "8px",
                  border: "1px solid #000",
                  width: "fit-content",
                }}
                onClick={() => sumbitRequest(true)}
              >
                Chấp nhận
              </p>
            </div>
          </div>
        </>
      ) : (
        <Typography.Text strong style={{ textAlign: "center" }}>
          Hiện không có yêu cầu
        </Typography.Text>
      )}
    </div>
  );
}
