import React from "react";
import {
  Layout,
  Menu,
  Switch,
  Typography,
  Popconfirm,
} from "antd";
import {
  ProfileOutlined,
  TeamOutlined,
  QrcodeOutlined,
  PoweroffOutlined,
  LineChartOutlined,
  DollarCircleFilled,
  OrderedListOutlined,
  SettingOutlined,
  KeyOutlined
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import "./index.scss";
import * as actions from "../../redux/actions/auth";
import { useDispatch } from "react-redux";
import { ChangePassword, Notifications } from "../../components";
import { checkPermission } from "../Permission";

const { Header, Sider, Content } = Layout;
export default function Home({ children }) {
  const [mode, setMode] = React.useState(false);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <SiderMenu mode={mode} />
      <Layout>
        <HeaderCustom setMode={setMode} mode={mode} />
        <Content style={{ padding: 20 }}>{children}</Content>
      </Layout>
    </Layout>
  );
}

const HeaderCustom = ({ setMode, mode }) => {
  const dispatch = useDispatch();
  function logout() {
    dispatch(actions.Logout());
  }
  return (
    <Header className="header-bar" style={!mode ? { background: "#fff" } : {}}>
      <div>
        <ChangePassword />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: 20,
          minWidth: 200,
        }}
      >
        <Notifications />
        <Switch
          onChange={() => setMode((prev) => !prev)}
          unCheckedChildren={
            <Typography.Text
              strong
              style={{ fontSize: 10, color: mode ? "#fff" : "#333" }}
            >
              Light
            </Typography.Text>
          }
          checkedChildren={
            <Typography.Text
              strong
              style={{ fontSize: 10, color: mode ? "#fff" : "#333" }}
            >
              Dark
            </Typography.Text>
          }
        />
        <Popconfirm title="Xác nhận đăng xuất" onConfirm={logout}>
          <PoweroffOutlined
            style={{
              fontSize: 30,
              color: mode ? "#7d0616" : "#bf021b",
              cursor: "pointer",
            }}
          />
        </Popconfirm>
      </div>
    </Header>
  );
};

const SiderMenu = ({ mode }) => {
  const history = useHistory();
  const [key] = React.useState(1);
  const [ROLE] = React.useState(localStorage.getItem("role"));

  const nav = [
    {
      key: "1",
      title: "Khách hàng",
      icon: <ProfileOutlined />,
      path: "/",
      permission: "MANAGE_CUSTOMER"
    },
    {
      key: "2",
      title: "Yêu cầu rút tiền",
      icon: <DollarCircleFilled />,
      path: "/requests",
      permission: "MANAGE_REQUEST"
    },
    {
      key: "3",
      title: "Mã giới thiệu",
      icon: <QrcodeOutlined />,
      path: "/refer-code",
      permission: "MANAGE_REFER_CODE"
    },
    {
      key: "4",
      title: "Nhân viên",
      icon: <TeamOutlined />,
      path: "/staffs",
      permission: "MANAGE_STAFF" 
    },
    {
      key: "5",
      title: "Thống kê",
      icon: <LineChartOutlined />,
      path: "/statistics",
      permission: "MANAGE_STATISTIC" 
    },
    {
      key: "6",
      title: "Cài đặt",
      icon: <SettingOutlined />,
      path: "/system-config",
      permission: "MANAGE_SETTING" 
    },
    {
      key: "7",
      title: "Quản lý báo lỗi",
      icon: <OrderedListOutlined />,
      path: "/reason",
      permission: "MANAGE_REQUEST_ERROR" 
    },
    {
      key: "8",
      title: "Phân Quyền",
      icon: <KeyOutlined />,
      path: "/permission",
      permission: "MANAGE_ROLE" 
    },
  ]

  return (
    <Sider collapsible defaultCollapsed={true}>
      <Menu
        mode="inline"
        theme={mode ? "dark" : "light"}
        style={{
          height: "100%",
          borderRight: 0,
          paddingTop: 64,
          minHeight: "100vh",
        }}
        activeKey={key}
      >
        {
          nav.map((item) => (
            checkPermission(item.permission) && (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                onClick={() => {
                  history.push(item.path);
                }}
              >
                {item.title}
              </Menu.Item>
            )
          ))
        }
      </Menu>
    </Sider>
  );
};
