/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import _store from "./redux/store";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider, useSelector, useDispatch } from "react-redux";
import {
  Login,
  Layout,
  Users,
  ReferCode,
  UserDetail,
  Staff,
  Statistics,
  Request,
  Reason,
  SystemConfig,
  Permission
} from "./containers";
import * as actions from "./redux/actions/auth";
import "./App.scss";
import { checkPermission } from "./containers/Permission";
const _history = createBrowserHistory();

const AuthorizedRoutes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state._auth);
  const [ROLE] = React.useState(localStorage.getItem("role"));

  React.useEffect(() => {
    const tokenFromStorage = localStorage.getItem("access_token");
    if (tokenFromStorage) {
      if (window.location.pathname === "/auth") {
        history.replace("/");
      }
      dispatch(actions.initialLogin());
    }
    if (!tokenFromStorage) {
      history.replace("/auth");
    }
  }, []);

  const router = [
    {
      path: "/",
      component: <Users />,
      exact: true,
      permission: "MANAGE_CUSTOMER"
    },
    {
      path: "/detail",
      component: <UserDetail />,
      exact: false,
      permission: "MANAGE_CUSTOMER"
    },
    {
      path: "/refer-code",
      component: <ReferCode />,
      exact: true,
      permission: "MANAGE_REFER_CODE"
    },
    {
      path: "/staffs",
      component: <Staff />,
      exact: false,
      permission: "MANAGE_STAFF"
    },
    {
      path: "/requests",
      component: <Request />,
      exact: false,
      permission: "MANAGE_REQUEST"
    },
    {
      path: "/statistics",
      component: <Statistics />,
      exact: false,
      permission: "MANAGE_STATISTIC"
    },
    {
      path: "/reason",
      component: <Reason />,
      exact: false,
      permission: "MANAGE_REQUEST_ERROR"
    },
    {
      path: "/system-config",
      component: <SystemConfig />,
      exact: false,
      permission: "MANAGE_SETTING"
    },
    {
      path: "/permission",
      component: <Permission />,
      exact: false,
      permission: "MANAGE_ROLE"
    }
  ]
  return (
    <>
      <>
        {status ? (
          <Layout>
            <Switch>
              {
                router.map((item, index) => {
                  if (checkPermission(item.permission)) {
                    return (
                      <Route
                        path={item.path}
                        exact={item.exact}
                        key={index}
                      >
                        {item.component}
                      </Route>
                    );
                  }
                })
              }
            </Switch>
          </Layout>
        ) : (
          <Switch>
            <Route exact path="/auth">
              <Login />
            </Route>
          </Switch>
        )}
      </>
    </>
  );
};

function App() {
  return (
    <Provider store={_store}>
      <Router history={_history}>
        <AuthorizedRoutes />
      </Router>
    </Provider>
  );
}

export default App;
