import { useEffect, useState } from "react"
import api from "../../api"
import { Button, Card, Checkbox, List, message } from "antd"

export const checkPermission = (permission) => {
  const role = window.localStorage.getItem("role") || ""
  const permisison = window.localStorage.getItem("permisison") || []
  if (role === "ROOT") {return true}
  else if (permisison.includes(permission)) {return true}
  else {return false}
}

export const listPermission = {
  "MANAGE_CUSTOMER": "Khách hàng",
  "MANAGE_REQUEST": "Yêu cầu rút tiền",
  "MANAGE_REFER_CODE": "Mã giới thiệu",
  "MANAGE_STAFF": "Nhân viên",
  "MANAGE_STATISTIC": "Thống kê",
  "MANAGE_SETTING": "Cài đặt",
  "MANAGE_REQUEST_ERROR": "Quản lý báo lỗi",
  "MANAGE_ROLE": "Phân quyền"
}

export default function Permission() {
  const [permissionStaff, setPermissionStaff] = useState([])
  const [permissionAdmin, setpermissionAdmin] = useState([])
  const [idStaff, setIdStaff] = useState("")
  const [idRoot, setIdRoot] = useState("")

  async function getPermission(params) {
    const { data } = await api.get(`/userRole`)

    if (data?.docs.find((item) => item.role === "ADMIN")) {
      setpermissionAdmin(data?.docs?.find((item) => item?.role === "ADMIN")?.permissions?.split(",") || [])
      setIdRoot(data?.docs?.find((item) => item?.role === "ADMIN")?._id || "")
    }
    if (data?.docs.find((item) => item.role === "STAFF")) {
      setPermissionStaff(data?.docs?.find((item) => item?.role === "STAFF")?.permissions?.split(",") || [])
      setIdStaff(data?.docs?.find((item) => item?.role === "STAFF")?._id || "")
    }
  }

  useEffect(() => {
    getPermission()
  }, [])

  async function handleSubmit() {
    await api.post(`/userRole/${idRoot}`, {
      permissions: permissionAdmin.join(","),
    })
    await api.post(`/userRole/${idStaff}`, {
      permissions: permissionStaff.join(","),
    })

    message.success("Cập nhật quyền thành công")
    getPermission()
  }

  return (
    <div>
      <Card style={{ width: "fit-content" }}>
        <div style={{ display: "flex" }}>
          <List
            header={
              <div style={{ fontSize: "20px", fontWeight: "600" }}>
                Quyền hạn
              </div>
            }
            itemLayout="horizontal"
            dataSource={Object.values(listPermission)}
            bordered
            renderItem={(item) => (
              <List.Item style={{ height: 50 }}>
                <span style={{ fontSize: "18px" }}>{item}</span>
              </List.Item>
            )}
          />
          <List
            header={
              <div style={{ fontSize: "20px", fontWeight: "600" }}>
                Admin
              </div>
            }
            itemLayout="horizontal"
            dataSource={Object.keys(listPermission)}
            bordered
            renderItem={(item) => (
              <List.Item style={{ height: 50, width: 150 }} key={item}>
                <Checkbox
                  checked={permissionAdmin.includes(item)}
                  onClick={() => {
                    if (permissionAdmin.includes(item)) {
                      setpermissionAdmin(prev => prev.filter(i => i !== item))
                    } else {
                      setpermissionAdmin(prev => [...prev, item])
                    }
                  }}
                ></Checkbox>
              </List.Item>
            )}
          />
          <List
            header={
              <div style={{ fontSize: "20px", fontWeight: "600" }}>
                Nhân viên
              </div>
            }
            itemLayout="horizontal"
            dataSource={Object.keys(listPermission)}
            bordered
            renderItem={(item) => (
              <List.Item style={{ height: 50, width: 150 }} key={item}>
                <Checkbox
                  checked={permissionStaff.includes(item)}
                  onClick={() => {
                    if (permissionStaff.includes(item)) {
                      setPermissionStaff(prev => prev.filter(i => i !== item))
                    } else {
                      setPermissionStaff(prev => [...prev, item])
                    }
                  }}
                ></Checkbox>
              </List.Item>
            )}
          />
        </div>
        <Button style={{
          marginTop: "20px",
          border: "1px solid #ccc",
          backgroundColor: "#52c41a",
          cursor: "pointer",
          color: "#fff",
          minWidth: "110px",
        }}
          onClick={() => handleSubmit()}
        >Cập nhật</Button>
      </Card>
    </div>
  )
}